import React from 'react';
import Block from '../../adapters/helpers/Block';
import { focusOnElement } from '../../adapters/helpers/Utils';
import { SkipNavigationBlockConstants } from '../../adapters/helpers/Constants';
import Link from 'next/link';

export default function SkipNavigationBlock(props) {
    const block = new Block(props);
    const label = block.getFieldValue(SkipNavigationBlockConstants.label);
    const targetID = block.getFieldValue(SkipNavigationBlockConstants.targetID);

    const goToContent = (event) => {
        if (targetID !== '' && document.getElementById(targetID) !== null) {
            return;
        }
        event.preventDefault();
        const mainContent = document.querySelector(SkipNavigationBlockConstants.roleMain);
        focusOnElement(mainContent);
    }

    return (
        <Link aria-label={label.fields.text} onClick={(event) => goToContent(event)} className={'visually-hidden ob-skip-navigation'} href={`#${targetID}`}>
            {label.fields.text}
        </Link>
    )
}
